import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalTitle,
  useTheme,
} from '@updater/ui-uds';
import { ComponentProps, FC } from 'react';
import { BrandedProEntity } from './BrandedProEntity';
import { useBrandedProModalQuery } from './BrandedProModalQuery';
import { BrandingContext } from './BrandingContext';

type BrandedProModalProps = ComponentProps<typeof Modal>;
export const BrandedProModal: FC<BrandedProModalProps> = (props) => {
  const theme = useTheme();
  const { data, loading } = useBrandedProModalQuery({
    variables: {
      input: {},
    },
  });

  if (loading) return null;

  return (
    <Modal {...props} renderAsChild>
      <ModalOverlay />

      <ModalContent data-testid="branded-pro-modal-content">
        <ModalHeader>
          <ModalTitle>Your Representatives</ModalTitle>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody
          flexDirection="column"
          width="100%"
          gap={`${theme.space.m}px`}
        >
          {data?.siteBranding?.entityBrandings
            ?.filter((entityBranding) => entityBranding?.kind === 'agent')
            ?.map((entityBranding) => {
              if (!entityBranding) return null;

              return (
                <BrandingContext.Provider
                  key={`${entityBranding?.name}-${entityBranding?.teamName}`}
                  value={entityBranding}
                >
                  <BrandedProEntity />
                </BrandingContext.Provider>
              );
            })}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
